import React, { Component } from 'react';
import { Alert} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ROOT } from '../../api-config';
import Swal from 'sweetalert2'
class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            license_num:'',
            name:'',
            address:'',
            city:'',
            province:'',
            postal_code:'',
            error:'',
            typing:false,
            allowSubmit:true,
            account_phone:'',
            website:'',
            first_name:'',
            last_name:'',
            email:'',
            phone:'',
            role:'',
            password:'',
            newsletter:''
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.triggerSearch = this.triggerSearch.bind(this)
    }

    componentWillMount() {
        this.timer = null;
    }

    componentDidMount() {
        document.title = "Licensee Sign Up | BevOps";
    }

    handleChange(event) {
        this.setState({
            typing:true,
            [event.target.id]: event.target.value
        });
        if (event.target.id=="license_num") {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.triggerSearch, 750);
        }
    }

    handleKeyDown(event) {
        if (event.keyCode === 13 && event.target.id=="license_num") {
            this.triggerSearch();
        }
    }

    triggerSearch() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT+'/account/validate/'+self.state.license_num)
            .then(function (response) {
                self.setState({
                    name:response.data.name,
                    address:response.data.address,
                    city:response.data.city,
                    province:response.data.province,
                    postal_code:response.data.postal_code,
                    error:'',
                    typing:false
                });
            })
            .catch(function (error) {
                self.setState({
                    error:error.response.data.Message,
                    typing:false
                });
            });
    }

    register = async event => {
        event.preventDefault();
        this.setState({
            allowSubmit:false
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT+'/account/register/'+self.state.license_num, self.state)
            .then(function (response) {
                localStorage.setItem('first_name', response.data.first_name);
                localStorage.setItem('last_name', response.data.last_name);
                localStorage.setItem('accounts', JSON.stringify(response.data.accounts));
                localStorage.setItem('show_welcome', true);
                self.props.refresh_from_local();
                self.props.userHasAuthenticated(true);
                self.props.history.push("/dashboard");
            })
            .catch(function (error) {
                Swal("Error", error.response.data.Message, "error");
                self.setState({
                    allowSubmit:true
                });
            });
    }


    render() {
        const { license_num, name, address, city, province, postal_code, error, typing, allowSubmit} = this.state;
        return (
            <div className="block-center mt-4 wd-xl">
                {/* START card */}
                <div className="card card-flat">
                    <div className="card-header text-center bg-dark">
                        <a href="">
                            <img className="block-center rounded logo_container" src="img/logo.png" alt="Logo"/>
                        </a>
                        <div style={{
                            color: "#fff",
                            textAlign: "center",
                            fontWeight: "bold",
                            borderTop: "1px solid #414384",
                            marginTop: "5px",
                            fontSize: "18px",
                            lineHeight: 1,
                            paddingTop: "5px"
                        }}>
                            <i className="small">HOME OF</i>
                            <div>BevOps &amp; Sandbagger</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">Sign Up Instantly!</p>
                        <div className="form-group">
                            <label className="text-muted" htmlFor="license_num">Enter Your Licensee # to Get Started:</label>
                            <div className="input-group with-focus">
                                <input className="form-control" id="license_num" type="text" placeholder="Licensee Number" onChange={this.handleChange}
                                       onKeyDown={this.handleKeyDown} required/>
                            </div>
                        </div>
                        <Alert color="danger" className={(error!='' && typing===false && license_num!='') ? 'text-center':'d-none'} ><i class="fa fa-warning"></i> {error}</Alert>
                        <form className={(this.state.name=='' || error!='') ? 'd-none':'mb-3'} onSubmit={this.register}>
                            <div className="form-group">
                                <label className="text-muted pt-1">Location Information:</label>
                                <div className="text-capitalize">
                                    <div className="font-weight-bold">{name}</div>
                                    <div>{address}, {city}, {province}, {postal_code}</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="account_phone" type="tel"  placeholder="On-Premise Phone # (optional)" onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-phone text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="website" type="text" placeholder="Business Website (optional)" onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-globe text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="text-muted pt-1">About You:</label>
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="first_name" type="text" placeholder="First Name" required onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-user text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="last_name" type="text" placeholder="Last Name" required onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-user text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="email" type="email" placeholder="Your Email" required onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-envelope text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="phone" type="tel" placeholder="Your Phone # (optional)" onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-phone text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="role" type="text" placeholder="Your Role/Title (optional)" autoComplete="off" onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-id-badge text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="password" type="password" placeholder="Password" autoComplete="off"  required onChange={this.handleChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>

                            <div className="checkbox c-checkbox mt-0">
                                <label>
                                    <input type="checkbox" value="1" name="newsletter" onChange={this.handleChange}  />
                                    <span className="fa fa-check"></span>Email me occasional promos & updates
                                </label>
                            </div>
                            <button className="btn btn-block btn-primary mt-3" type="submit" disabled={!allowSubmit}>Create account</button>
                        </form>
                        <p className="pt-3 text-center">Have an account?<br></br><Link to="login">Sign In</Link></p>
                        <p className="pt-3 text-center"><a href="mailto:info@thewebpeople.ca">Questions / Support</a></p>
                    </div>
                </div>
                {/* END card */}
                <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>2024</span>
                    <span className="mx-2">-</span>
                    <span>BevOps</span>
                </div>
            </div>
        );
    }
}

export default Register;
